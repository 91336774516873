<template lang="html">
    <div class="container main">
        <top-navigation-bar
            title="Regression"
            right-nav="reset"
            @click="doReset"
        />

        <div class="divider">
            <h4 class="mb-3">
                Confidence Interval for:
            </h4>
            <div class="text-left">
                <h3>M = slope</h3>
                <h3>m = slope estimate</h3>
            </div>
        </div>

        <div class="">
            <h4 class="mb-3 mt-2">
                Regression Equation:
            </h4>
            <div class="m-view my-3">
                <h3>ŷ = {{ allData.regrEquation[1] }}{{ allData.eqSign }}{{ allData.regrEquation[0] }}{{ 'x' }}</h3>
                <h3>{{ 'm = ' }}{{ allData.dataSS.m }}</h3>
            </div>
        </div>

        <div class="divider">
            <input-single
                ref="refCL"
                v-model="clInput"
                label="Confidence Level"
                placeholder="%"
                style-class="input-single"
                @enter-pressed="onSubmitEdit()"
            />
        </div>

        <div class="mt-3">
            <h4>{{ textOutput.responseText[0] }} {{ ciOutputDisplay + '% ' }} {{ textOutput.responseText[1] }}</h4>
            <h4 class="m-output-text">
                ({{ ciOutput.lowerLimit }}, {{ ciOutput.upperLimit }})
            </h4>
            <h4>{{ textOutput.finalText[0] }} {{ ciOutputDisplay + '%' }} {{ textOutput.finalText[1] }} {{ ciOutput.lowerLimit }} {{ textOutput.finalText[2] }} {{ ciOutput.upperLimit }}</h4>
        </div>
    </div>
</template>

<script>
import TopNavigationBar from '@/components/top-navigation-bar.vue'
import InputSingle from '@/components/input-single.vue'
import rData from '@/js/regression/regression-data.js'
import { isEmptyNumsCustom, isErrorCI, isInvalidNums, clearData } from '@/js/lib/input-check.js'
import localStore from '@/store/localStore.js'
import _ from 'lodash'

export default {
    name: 'Mci',
    components: {
        TopNavigationBar,
        InputSingle
    },
    data () {
        return {
            clInput: '',
            ciOutput: '',
            allData: [],
            textOutput: {
                responseText: ['The ', 'C.I for the slope is: '],
                finalText: ['We are ', 'confident that the slope M of the regression line is between ', ' and '],
            }
        }
    },
    computed: {
        ciOutputDisplay () {
            const output = _.get(this, 'ciOutput', '')
            return output ? this.clInput : ''
        }
    },
    beforeMount () {
        this.allData = localStore.getStore('regression-data')
    },
    methods: {
        doReset () {
            _.assign(this, clearData(this, ['clInput', 'ciOutput']))
        },
        onSubmitEdit () {
            var { allData, clInput } = this
            if (isEmptyNumsCustom(clInput)) {
                return
            }
            clInput = _.toNumber(clInput)

            if (isErrorCI(clInput) || isInvalidNums(clInput)) {
                this.ciOutput = ''
                return
            }
            const ci = rData.calculateCI('M', allData, clInput)
            this.ciOutput = ci
        }
    },
}
</script>

<style lang="css">
    .m-output-text {
        font-weight: bolder;
        padding: 10px;
        letter-spacing: 1px;
    }
</style>
